/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://5bw33zj2bfdn7gnz7zm4dxsyum.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-west-2:88139b17-b8ff-44a7-a79f-c7aaa84ed2d2",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_8YLz3oxIT",
    "aws_user_pools_web_client_id": "m0gho8ldmbpj7pn165dbjoere",
    "oauth": {
        "domain": "6v194xvz4llt-prod.auth.us-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.thoughts.mobi,https://www.thoughts.mobi/thoughts,https://www.thoughts.mobi/",
        "redirectSignOut": "https://www.thoughts.mobi,https://www.thoughts.mobi/thoughts,https://www.thoughts.mobi/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "predictions": {
        "convert": {
            "transcription": {
                "region": "us-west-2",
                "proxy": false,
                "defaults": {
                    "language": "en-US"
                }
            }
        }
    }
};


export default awsmobile;
